@import "../../variables.scss";

.DataOperations {
  .section-features {
    background-color: $light-gray-2;
  }
  .primary-tr > td {
    background-color: $table-gray-bg-3;
    font-size: $font-size-small;
    font-weight: bold;
    color: $gray-600;
    border-top: 1px solid $gray-border-color-2;
    border-left: 1px solid $gray-border-color-2;
    border-right: 1px solid $gray-border-color-2;
    vertical-align: middle;
  }
  .secondary-tr > td {
    background-color: $table-gray-bg-4;
    font-size: $font-size-small;
    color: $white;
    border: 1px solid $gray-border-color-2;
    vertical-align: middle;
  }
  .default-tr > td {
    border: 1px solid $gray-border-color-2;
    font-size: $font-size-small;
    color: $gray-600;
    vertical-align: middle;
  }
  .pull-right {
    text-align: right;
  }
}
