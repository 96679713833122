@import "../../variables.scss";

.sx-report-widget-container {
  box-shadow: $box-shadow-light;
  border-radius: calc(2 * #{$base-border-radius});
  min-height: 30rem;
  &__header {
    border-top-left-radius: calc(2 * #{$base-border-radius});
    border-top-right-radius: calc(2 * #{$base-border-radius});
    background-color: $table-head-color;
    color: $white;
    font-size: $base-font-size;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__search {
    background-color: #f6f8fa;
    border: 1px solid #c9c4c4;

    input {
      border-radius: 2rem;
      padding-left: 2rem;
    }
  }

  &__search-icon {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
  }

  &__refresh {
    font-weight: bold;
    color: $white;
    font-weight: bold;
    cursor: pointer;
  }
}
