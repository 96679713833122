@import "./variables.scss";

.sx-shadow {
  box-shadow: $box-shadow-1 !important;
}

.sx-hover-shadow {
  transition: all 0.2s ease;

  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.color-primary {
  color: $primary;
}

.bg-accent {
  background-color: $accent-blue;
}

.color-accent {
  color: $accent-blue;
}

.color-white {
  color: $white;
}

.font-base {
  font-size: $font-size-base !important;
}

.font-large {
  font-size: $font-size-large !important;
}

.font-medium {
  font-size: $font-size-medium !important;
}

.font-small {
  font-size: $font-size-small !important;
}

.font-xsmall {
  font-size: $font-size-xsmall !important;
}

.vertical-bar {
  border-left: 1px solid $light-gray-1;
  height: 3rem;
  display: inline-block;
  margin: 0 1rem;
}

.pointer {
  cursor: pointer;
}

.gray-bottom-border-1 {
  border-bottom: 1px solid $gray-line-color;
}

.white-space-nowrap {
  white-space: nowrap;
}

.dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.huge-container {
  max-width: 1600px;
}

.grow {
  transition: all 0.1s ease-in-out;
}

.grow:hover {
  transform: scale(1.03);
}

.help-fa-link {
  background-color: #2aaeeb;
  color: white;
  padding: 5px;
  border-radius: 50%;
}

.search-primary {
  color: $primary-2;
}