@import "../../variables.scss";

.Home {
  .section-features {
    background-color: $light-gray-2;
  }

  .section-help {
    background-color: $white;
  }

  .question-icon {
    font-size: 2.6rem;
    color: #b4cdd5;
  }

  .learn-more-btn,
  .learn-more-btn:focus,
  .learn-more-btn:active {
    margin-left: 2rem;
    padding: 0.5rem 2rem;
    background: linear-gradient($accent-2, darken($accent-2, 20));
    border-color: $accent-2 !important;
    border-radius: 3.2rem !important;

    &:hover {
      background-color: darken($accent-2, 20);
    }
  }

  /* .section-banner {
    position: relative;
    height: 28rem;
    color: $white;
    background-color: $primary;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    img {
      object-fit: cover;
      opacity: 0.2;
      width: 100%;
      height: 100%;
    }

    .banner-text {
      position: absolute;
      top: 24%;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      @media (max-width: 876px) {
        top: 8%;
      }

      .learn-more-btn,
      .learn-more-btn:focus,
      .learn-more-btn:active {
        margin: 2rem auto;
        padding: 1rem 4rem;
        background: linear-gradient($accent-2, darken($accent-2, 20));
        border-color: $accent-2 !important;
        border-radius: 3.2rem !important;

        &:hover {
          background-color: darken($accent-2, 20);
        }
      }
    }

    .header-text {
      font-size: 3.2rem;
      font-weight: bold;
      padding-bottom: 1.5rem;
    }

    .subtext {
      font-size: 1.8rem;
    }
  } */
}
