.role-management-modal {
  margin-top: -20px !important;
  .modal-header {
    padding: 0 1rem;
    align-items: center;
  }
  .modal-body {
    padding: 0.5rem 1rem 1rem 1rem;
  }
}
.db-popover {
  .popover-body {
    padding: 0.3rem 0.7rem !important;
    font-size: 13px;
    line-height: 16px;
    color: #707070;
    background-color: #fff;
  }
}
.db-popover.show.popover.bs-popover-bottom {
  max-width: unset;
}

.accordion__button {
  padding: 0 !important;
  background-color: #fff !important;
}
.accordion__heading {
  border: none !important;
}
.accordion__panel {
  padding: 0 !important;
}
.accordion {
  border: none !important;
}
.accordion__button {
  display: flex !important;
  align-items: center;
}

.accordion__button:before {
  height: 8px !important;
  width: 8px !important;
}

.cust-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
}

.cust-scroll::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
.cust-scroll::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 50px;
}

/* Handle */
.cust-scroll::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 50px;
}

/* Handle on hover */
.cust-scroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.accordion__button:before {
  display: inline-block;
  content: "+" !important;
  height: 10px;
  width: 10px;
  margin-right: 12px !important;
  font-size: 20px !important;
  margin-top: -23px !important;
  border-bottom: none !important;
  border-right: none !important;
  transform: rotate(0deg) !important;
  color: #747171 !important;
}
.sub-cat-btn {
  .accordion__button:before {
    padding-left: 20px !important;
  }
}

.accordion__button[aria-expanded="true"]::before {
  content: "_" !important;
  margin-top: -42px !important;
}

.text-box-icon-rm {
  color: #6c6c6c;
  font-size: 18px;
  z-index: 4;
  cursor: pointer;
}
.role-management-modal {
  [hidden] {
    display: block !important;
  }
}

@mixin switch($res: "sm") {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == "md" {
    $index: 2rem;
    $mainVal: 1.5rem;
  } @else if $res == "lg" {
    $index: 3rem;
    $mainVal: 2rem;
  } @else if $res == "xl" {
    $index: 4rem;
    $mainVal: 2.5rem;
  }

  .custom-control-label {
    padding-left: #{$index};
    padding-bottom: #{$mainVal};
  }

  .custom-control-label::before {
    height: $mainVal;
    width: calc(#{$index} + 0.75rem);
    border-radius: $mainVal * 2;
  }

  .custom-control-label::after {
    width: calc(#{$mainVal} - 4px);
    height: calc(#{$mainVal} - 4px);
    border-radius: calc(#{$index} - (#{$mainVal} / 2));
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(#{$mainVal} - 0.25rem));
  }
}

.custom-switch.custom-switch-sm {
  @include switch();
}

.custom-switch.custom-switch-md {
  @include switch("md");
}

.custom-switch.custom-switch-lg {
  @include switch("lg");
}

.custom-switch.custom-switch-xl {
  @include switch("xl");
}

.indeterminate {
  // input[type="checkbox"]::before {
  //   position: absolute;
  //   content: "\220E" !important;
  //   font-size: 14px;
  //   top: 0;
  //   color: white;
  //   z-index: 1000;
  // }
  input[type="checkbox"] {
    accent-color: #888686;
  }
}
