.whatsnew-btn {
  .btn-primary:focus,
  btn-primary:hover,
  .btn-primary:active {
    background: #0f59aa 0% 0% no-repeat padding-box !important;
    border: none;
    box-shadow: none !important;
  }

  .btn-primary:disabled {
    color: #0f59aa;
    background-color: #e4eaf5;
    border-color: #e4eaf5;
    opacity: 0.5;
  }
}

.whats-new-btn-active {
  height: 5.4rem;
  background: #0f59aa 0% 0% no-repeat padding-box;
  border: 0;
  outline: 0;
  border-radius: 2.6rem;
  padding: 1rem 3rem;
  margin-right: 2rem;
  opacity: 1;
  font: normal normal 200 2rem Arial;
  letter-spacing: 0px;
  color: #ffffff;
}
.whats-new-btn-active:hover {
  background: #0f59aa 0% 0% no-repeat padding-box;
}

.whats-new-btn {
  height: 5.4rem;
  background: #e4eaf5 0% 0% no-repeat padding-box;
  border: 0;
  outline: 0;
  border-radius: 2.6rem;
  padding: 1rem 3rem;
  margin-right: 2rem;
  opacity: 1;
  font: normal normal 200 2rem Arial;
  letter-spacing: 0px;
  color: #0f59aa;
}
.whats-new-btn:hover {
  background: #0f59aa 0% 0% no-repeat padding-box;
  opacity: 0.6;
}
.whats-new-btn:focus {
  background: #0f59aa 0% 0% no-repeat padding-box !important;
  opacity: 0.6;
}

.whatsnew-btn {
  a:link,
  a:visited,
  a:active {
    display: inline;
    color: #0f59aa;
    font-size: 18px;
  }

  a:hover {
    font-size: 18px;
    display: inline;
    color: #12506f;
  }
}

.whatsnew-heading {
  color: #0f59aa;
  font-size: 32px;
}
.whatsnew {
  margin-bottom: 2rem;
  h5 {
    margin-bottom: 0;
    font-weight: bold;
  }
  span {
    font-size: 18px;
  }
}

.whatsnew-box {
  background: #e4eaf5;
  border-radius: 15px;
  margin-top: 18.5rem;
  margin-left: 10px;
}
