@import "../../variables.scss";

$badge-size: 1.6rem;

.DashboardCard {
  &__image-container {
    height: 12.9rem;
    max-width: 25rem;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title-section {
    font-weight: bold;
    color: $primary;
    text-align: center;
  }

  &__tag-icon {
    height: 1.8rem;
  }

  &__badge {
    font-weight: bold;
    font-size: 1.1rem;
    color: $white;
    background-color: $accent-2;
    text-align: center;
    line-height: $badge-size;
    height: $badge-size;
    width: $badge-size;
    position: absolute;
    border-radius: 50%;
    top: -0.7rem;
    left: 0.8rem;
  }

  &__fav-icon {
    color: $accent-2;
  }

  &__grey-icon {
    color: $gray-line-color;
  }
}

.domain-as-db-card {
  background-color: $accent-2 !important;
}

.not-authorized:hover {
  transform: none;
  box-shadow: none;
}

.sx-dashboard-category__auth-modal-backdrop {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1040;
}

.sx-dashboard-category__auth-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 50%;
  opacity: 0.7;
  border-radius: 1.25rem;
  color: white;
  text-align: center;
  padding: 2rem;
  z-index: 1000;
}
