@import "../../variables.scss";

.Landing {
  .section-banner {
    position: relative;
    height: 55rem;
    color: $white;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .banner-text {
      position: absolute;
      top: 18%;
      left: 32%;
      transform: translateX(-50%);
      @media (max-width: 1500px) {
        left: 35%;
      }
      @media (max-width: 1200px) {
        top: 12%;
        left: 38%;
      }
      /* @media (max-width: 992px) {
        left: 38%;
      } */

      .show-btn,
      .show-btn:focus,
      .show-btn:active {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        background: linear-gradient($accent-2, darken($accent-2, 20));
        border-color: $accent-2 !important;
        border-radius: 3.2rem !important;

        &:hover {
          background-color: darken($accent-2, 20);
        }
      }
    }

    .header-text {
      font-size: 8rem;
      font-weight: bold;
      line-height: 10rem;
    }

    .subtext {
      font-size: 3.2rem;
      padding-bottom: 1.6rem;
      font-weight: bold;
    }
  }

  ul {
    list-style-type: none;
  }

  li::before {
    content: "– ";
  }

  .section-features {
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    &:nth-child(even) {
      background-color: #ffffff;
    }
    .explore-btn {
      border-radius: 3rem;
      background-color: $accent-1;
      border-color: $accent-1;
      color: $white;
      margin-top: 2rem;
      padding: 1rem 2.5rem;
      font-size: $font-size-small;
      align-self: flex-start;

      &:hover,
      &:focus,
      &:active {
        background-color: $primary !important;
        border-color: $primary;
      }
    }
  }

  .section-footer {
    background-color: #736d7c;
    border: 1px solid #c7dae2;
    color: #ffffffbd;
  }
}

.modal-w-75 {
  width: 90%;
  .modal-content {
    width: auto;
    .modal-header {
      background-color: $accent-1;
      color: white;
      .close {
        transform: scale(1.5);
        color: white;
        font-weight: normal;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
