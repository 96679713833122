@import "./variables.scss";

a,
a:hover,
a:focus,
a:active,
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  text-decoration: none !important;
  color: $accent-blue;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
  font-size: $font-size-small;
  padding: 1.2rem 1.6rem;

  a {
    color: $accent-blue;
    text-decoration: none;
    button,
    button:hover,
    button:focus,
    button:active {
      color: $accent-blue;
      font-size: $font-size-small;
      text-decoration: none !important;
    }
  }

  li.active {
    color: $active-tab-text-color;
  }
}

.pagination {
  .page-item {
    .page-link {
      padding: 0;
      background-color: $white;
      border-color: $white;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      border-radius: 50%;
    }
    &.active {
      .page-link {
        background-color: $accent-1;
        border-color: $accent-1;
      }
    }
  }
}

// Making checkboxes blue
.form-check {
  padding-left: 0px;

  .form-check-input {
    display: none;

    + .form-check-label {
      padding-left: 0;

      // This portion makes the check exist, but transparently
      // Needed for the animation
      &:after {
        transition: all 0.3s ease;
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 0px;
        width: 6px;
        height: 8px;
        border-color: transparent;
        border: solid transparent;
        border-width: 0 2px 2px 0;
        transform: rotate(-45deg);
      }

      &:before {
        margin-right: 7px !important;
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #c3bcbc;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-bottom: 1px;
      }
    }

    &:checked {
      + .form-check-label {
        &:before {
          border: 1px solid $accent-1;
          margin-bottom: 1px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          // top: 7px;
          top: 4px;
          left: 5px;
          width: 6px;
          height: 8px;
          border-color: $accent-1;
          border: solid $accent-1;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
