@import "../../variables.scss";

.sx-check-with-count {
  font-size: $font-size-xsmall;

  label {
    padding-left: 1rem;
    color: $secondary;
  }

  &__count {
    background-color: #c9c4c4;
    color: $white;
    border-radius: 50%;
    padding-right: 1px;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
  }
}
