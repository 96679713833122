@import "../../variables.scss";

.LandingCourseLibrary {
  .course-library-div {
    padding: 0;
    .category-title-div {
      background-color: #736e7d;
      color: white;
      margin: -1rem -1rem 0.5rem -1rem !important;
      .category-title-center {
        padding: 0.8rem 0;
        text-align: left;
        .category-title {
          font: normal bold normal 3.4rem Arial;
          margin-left: 2rem;
        }
      }
      .category-description {
        font: normal normal normal 2rem Arial;
      }
    }

    .learning-paths-div {
      max-height: 60vh !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      box-sizing: content-box;
    }
    .learning-paths-div::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .learning-path-div {
      .learning-path-title {
        font: normal normal normal 2.2rem Arial;
        margin-left: 3rem;
        margin-top: 1.5rem;
        color: #75808e;
      }
    }
    .learning-path-div:not(:last-child) {
      border-bottom: 0.01rem solid rgba(112, 112, 112, 0.3);
    }
    .landing-course-div {
      .course-title {
        font: normal normal normal 1.4rem Arial;
      }
      .course-skillpoints {
        font: normal normal normal 1.2rem Arial;
      }
      .course-description {
        font: normal normal normal 1.2rem Arial;
        .view-details-text {
          cursor: pointer;
          color: #01acf1;
          font-size: 1.6rem;
          text-decoration: underline;
        }
      }

      .landing-course-thumbnail {
        border-radius: 1.5rem;
        width: 31rem;
        height: 18rem;
        border: 0.01rem solid rgba(112, 112, 112, 0.3);
        position: relative;
        // .play-icon-container {
        //   background-color: whitesmoke;
        //   height: 4rem;
        //   width: 4rem;
        //   border-radius: 2rem;
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        //   -webkit-transform: translate(-50%, -50%);
        // }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1.5rem;
        }
      }
    }
  }
}
.course-details-card {
  width: 52rem !important;
  margin-top: -14rem !important;
  margin-left: -15rem !important;
  border: 1px solid #9e9e9e;
  -webkit-box-shadow: 3px 5px 5px #9e9e9e;
  -moz-box-shadow: 3px 5px 5px #9e9e9e;
  box-shadow: 3px 5px 5px #9e9e9e;
  .course-details-img {
    width: 52rem;
    height: 30.5rem;
    object-fit: cover;
  }
  .duration-container {
    background-color: #3b3b3b;
    height: 4rem;
    width: 11.5rem;
    font: normal normal bold 2rem Arial;
    color: white;
    position: absolute;
    top: calc(30.5rem - 2rem);
    left: calc(100% - 5.75rem);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .learning-path-text {
    color: #01acf1;
    font-size: 1.6rem;
  }
  .details-row {
    margin: 0;
    padding: 0;
    :not(:last-child) {
      border-right: 1px solid grey !important;
      padding-right: 0.7rem;
    }
    :not(:first-child) {
      padding-left: 0.7rem;
    }
  }
  .description-container {
    height: 140px !important;
    overflow-y: scroll;
    padding-right: 10px;
    justify-content: safe;
  }
  ::-webkit-scrollbar {
    width: 3px;
    scroll-behavior: smooth;
  }
  ::-webkit-scrollbar-track {
    background: rgb(245, 245, 245);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #01acf1;
    border-radius: 1.5px;
  }
  z-index: 9999;
}
.LandingCourseLibrary::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
