@import "../../variables.scss";

.sx-icon-card {
  min-height: 24rem;
  border-radius: $base-border-radius;
  padding: 1rem;
  transition: all 0.2s ease;
  box-shadow: $box-shadow;

  &.sx-primary {
    background-color: $accent-blue;
    color: $white;
  }

  &.sx-secondary {
    background-color: $white;
    color: $primary;
    border: 1px solid $gray-100;
  }

  &__image {
    padding-bottom: 1.6rem;
  }

  &__title {
    font-weight: bold;
    font-size: $font-size-medium;
  }

  &__secondary-title {
    font-size: 1.4rem;
    font-weight: 700;
  }

  &__subtitle {
    font-size: $font-size-xsmall;
    padding-top: $base-font-size;
  }

  &.sx-disabled {
    background-color: $white;
    color: #82a4b4;
  }

  &__badge {
    position: absolute;
    right: 0;
    top: 0;
    font-size: $font-size-xsmall;
    background-color: #82a4b4;
    color: $white;
    padding: 0.5rem 0.8rem;
    border-top-right-radius: $base-border-radius;
    border-bottom-left-radius: $base-border-radius;
  }
}
