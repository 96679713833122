.ToggleDisclaimer {
  .show-btn {
    box-shadow: none !important;
  }
  .disclaimer-icon {
    max-width: 40px;
  }
  .disclaimer-close {
    position: absolute;
    top: 0.5em;
    right: 1.2em;
    .close {
      color: #12506f;
    }
  }
}
