@import "../../variables.scss";

.MenuPageTemplate {
  .sx-base-card {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 10.8rem);
  }

  .section-banner {
    position: relative;
    height: calc(100vh - 37rem);
    color: $white;
    background-color: $primary;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    img {
      object-fit: cover;
      opacity: 0.2;
      width: 100%;
      height: 100%;
    }

    .banner-text {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 576px) {
        top: 30%;
      }
    }

    .header-text {
      font-size: 3.2rem;
      font-weight: bold;
      padding-bottom: 1.5rem;
    }

    .subtext {
      font-size: 1.8rem;
    }
  }

  .section-middle {
    background-color: $light-gray-2;
    flex-grow: 1;

    .container-fluid {
      position: relative;
      text-align: center;
      max-width: 1200px;
    }

    .row {
      position: absolute;
      top: -15rem;
      width: 100%;
    }

    .px-closer {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }

    .sx-icon-card {
      @media (min-width: 576px) {
        width: 20rem;
        height: 18rem;
      }
      @media (min-width: 992px) {
        width: 28rem;
        height: 25rem;
      }
      @media (min-width: 1200px) {
        width: 33rem;
        height: 30rem;
      }
    }

    &.length-4 {
      @media (min-width: 576px) {
        .sx-icon-card {
          width: 20rem;
          height: 18rem;
        }
      }
      @media (min-width: 992px) {
        .sx-icon-card {
          width: 22rem;
          height: 25rem;
        }
      }
      @media (min-width: 1200px) {
        .sx-icon-card {
          width: 27rem;
          height: 27rem;
        }
      }
    }
  }

  .section-footer {
    height: 6rem;
    background-color: #8092ab;
    border: 1px solid #c7dae2;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
    color: $white;
  }
}
