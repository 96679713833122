.asset-management-modal {
  min-width: 50vw !important;
}
.add-btn,
.add-btn:hover {
  font-weight: bold;
  text-transform: capitalize;
  color: white;
  background: #01acf1 !important;
  border: 1px solid #01acf1 !important;
}

.add-btn {
  padding: 0.4em 2em;
  border-radius: 0.7em;
}

.edit-btn {
  padding: 0.4em 2em;
  border-radius: 1.4em;
}
