@import "../../variables.scss";

.sx-toast {
  min-width: 30rem;
  min-height: 10rem;
  box-shadow: $box-shadow-light;
  transition: box-shadow 0.1s ease;

  .toast-header {
    background-color: inherit;
    color: $white;
    font-size: $font-size-medium;
    padding: 0.5rem 1rem;

    svg {
      margin-bottom: 0.4rem;
      margin-right: 0.6rem;
    }

    // Don't include the default close button
    button.close {
      display: none;
    }
  }

  .toast-body {
    color: $white;
    font-size: $font-size-base;
    padding: 2rem 1.5rem;
  }

  &.sx-success {
    background-color: lighten($success, 7);

    .toast-header {
      border-bottom: 1px solid lighten($success, 20);
    }
  }

  &.sx-info {
    background-color: $accent-1;

    .toast-header {
      border-bottom: 1px solid lighten($accent-1, 20);
    }
  }

  &.sx-fail {
    background-color: $danger;

    .toast-header {
      border-bottom: 1px solid lighten($danger, 20);
    }
  }

  &.sx-custom {
    background-color: $custom-alert;

    .toast-header {
      border-bottom: 1px solid lighten($custom-alert, 20);
      font-size: $base-font-size;
    }
    .toast-body {
      background-color: lighten($custom-alert, 5);
    }
  }
}
