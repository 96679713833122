@import "../../variables.scss";

.AssetDescriptionPage {
  padding: 1.5rem;
  .sx-base-card {
    min-height: calc(100vh - #{$navbar-height} - 3rem);
  }

  .sx-asset-description {
    &__banner-section {
      background-color: #7f92ac;
      color: $white;
    }

    &__bottom-border-row:not(:last-child) {
      border-bottom: 1px solid lighten(#7f92ac, 10);
    }
    &__drcard {
      border-radius: 10px;
      overflow: hidden;
    }
    &__dr-card-header {
      background-color: #3c98c0;
      color: white;
    }
  }

  .sx-category-item {
    &.primary {
      .sx-category-item__category {
        color: lighten(#7f92ac, 20);
      }
      .sx-category-item__value {
        color: $white;
      }
    }
    &.secondary {
      .sx-category-item__category {
        color: $secondary;
      }
      .sx-category-item__value {
        color: $primary;
      }
    }
    &__category {
      font-weight: bold;
      font-size: $font-size-xsmall;
    }

    &__value {
      font-weight: bold;
      font-size: $font-size-small;
      .scoreCol:not(:last-child) {
        border-right: 1px solid lighten(#7f92ac, 10);
      }
      .inActiveClass {
        filter: brightness(75%);
      }
    }
    &__drcategory {
      font-size: $font-size-small;
      font-weight: bold;
    }
    &__drvalue {
      font-size: $font-size-small;
    }
  }
  .elements-table-body {
    height: 60rem;
    overflow: auto;
  }
}

.sx-asset-description {
  .doc-header {
    background: #eef2f5;
    color: #636363;
    font-weight: bold;
  }
  .doc-row {
    color: #636363;
  }
}
