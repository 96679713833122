@import "../../variables.scss";

.DashboardCategories {
  &__section-categories {
    background-color: lighten($body-background-color, 3);
    border: 1px solid $gray-300;
  }

  &__dashboards-section {
    position: relative;
    background-color: $white;
    border: 1px solid $gray-300;
  }
}
