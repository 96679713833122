@import "../../variables.scss";

.SelfServicePages {
  .right-section {
    background-color: $light-gray-2;
    border-bottom-right-radius: $base-border-radius;

    border-left: 1px solid $gray-line-color;
    padding-left: 0;
    padding-right: 0;
    .video-tabs-container {
      padding: 5rem 0;
    }
    .video-tab {
      color: $primary;
    }
    .active-tab {
      background-color: $primary;
      color: white;
    }
    .active-indicator {
      position: relative;
    }
    .active-indicator:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      width: 0;
      height: 0;
      left: -1px;
      top: 40%;
      border: 10px solid transparent;
      border-left-color: white;
    }
  }

  .resource-links {
    padding: 5rem;
    border-bottom: 1px solid $gray-line-color;
  }

  .resource-tiles {
    padding: 5rem;
  }

  .SelfServiceCard {
    background-color: $accent-1;
    color: $white;

    a.download-button {
      background-color: $white;
      border-radius: 3rem;
      border-color: $white;
      opacity: 0.8;
      color: $accent-1;
      font-size: $font-size-small;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
