@import "../../variables.scss";

.sx-footer {
  position: relative;
  min-height: 5rem;
  background-color: black;
  border: 1px solid #c7dae2;
  .sx-footer-section {
    margin-left: 2rem;
    margin-right: 2rem;
    padding-top: 2rem;
  }

  .sx-footer-button {
    background-color: orange;
  }
  .sx-footer-title {
    margin-top: -10px;
    font-weight: 600;
    font-size: 1.5rem;
    color: $secondary !important;
  }
  .sx-footer-text {
    font-size: 1.4rem;
    color: $secondary;
    padding-left: 0;
    text-align: left;
  }
  .sx-footer-subtitle {
    font-family: Arial;
    font-weight: bold;
    margin-top: 1.3rem;
    color: $white;
  }
  .sx-logo-font {
    color: $secondary;
    font-size: 1.4rem;
  }
}

.contact-us-icon {
  font-size: 2.2rem;
  margin-top: -18px !important;
  cursor: pointer;
  padding-right: 1rem;
  a {
    color: $secondary !important;
    transition: color 0.1s ease;
    &:hover {
      color: darken($secondary, 20) !important;
    }
  }
}
.contact-us-icon-2 {
  font-size: 1.8rem;
  margin-top: -15px !important;
  cursor: pointer;
  padding-right: 1rem;
  a {
    color: $secondary !important;
    transition: color 0.1s ease;
    &:hover {
      color: darken($secondary, 20) !important;
    }
  }
}

.sx-footer {
  .sx-footer-form {
    display: block;
    width: 100%;
    .form-group {
      .form-label {
        width: 100%;
        margin-bottom: 3rem;
      }
      .form-control {
        display: inline-block;
        width: calc(100% - 9rem);
        border-radius: 0px;
      }
      .sx-footer-button {
        width: 9rem;
        background-color: darken($accent-2, 20);
        height: 32px;
        border: 1px solid darken($accent-2, 20);
        border-radius: 0rem;
        line-height: 1.6;
        border: 0rem;
      }
    }
  }
}
