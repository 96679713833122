@import "../../variables.scss";

.sx-chipset {
  button,
  button:hover,
  button:active,
  button:focus {
    color: $accent-1;
    text-decoration: none;
  }
}
