//colors
$primary: #12506f;
$primary-2: #0D5AAA;
$secondary: #707070;
// $info: //TODO
// $danger: //TODO
$accent-1: #2aaeeb;
$accent-2: #f9b64b;
$custom-alert: #f9b64b;

// Bootstrap variable overrides
$primary: #12506f;
$gray-600: #707070;
$font-family-base: "arial";
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// ) !default;

// New custom variables
$body-background-color: #e6eaef;
$accent-blue: #2aaeeb;
$box-shadow-color: #dedede;
$table-head-color: #3c98c0;
$table-gray-bg-1: #eef2f5;
$table-gray-bg-2: lighten(#eef2f5, 5);
$table-gray-bg-3: #e6eaef;
$table-gray-bg-4: #a1aeb5;
$box-shadow-1: 0px 0px 2.3rem $box-shadow-color;
$box-shadow-light: 0px 0px 1rem #00000029;
$base-border-radius: 0.5rem;
$navbar-color: $primary;
$navbar-height: 7.4rem;

$navbar-active-color: #1c7fa2;

$light-gray-1: lighten(#959595, 30);
$light-gray-2: #f2f7f8;
$gray-line-color: lighten(#959595, 20);
$active-tab-text-color: #707070;
$gray-border-color: #707070;
$gray-border-color-2: #a1aeb5;

$white: white;

$spacer: 1.6rem;
$font-size-base: 1.6rem;
$base-font-size: 1.6rem;
$font-size-large: 2.8rem;
$font-size-medium: 2rem;
$font-size-small: 1.4rem;
$font-size-xsmall: 1.2rem;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

:export {
  primary: $primary;
  primary-2: $primary-2;
  secondary: $secondary;
  info: $info;
  accent1: $accent-1;
  accent2: $accent-2;
  tableGrayBg1: $table-gray-bg-1;
  tableGrayBg2: $table-gray-bg-2;
  navbarHeight: $navbar-height;
}
