.du-banner-modal {
  color: #939393;
  max-width: 110rem;
  align-self: safe center !important;
  .modal-body {
    padding: 0;
  }
  .name-highlight {
    color: #44b58e;
  }
  .congrats-blue {
    color: #135070;
  }

  .mail-text {
    font-size: 0.8em;
  }
  .mail-subject {
    color: #707070;
  }
  .start-btn {
    padding: 0.5em 2.5em;
    border-radius: 1.4em;
    font-weight: bold;
    text-transform: capitalize;
    background: #f0a934 !important;
    border: 1px solid #f0a934 !important;
  }
}

.apply-btn,
.apply-btn:hover {
  padding: 0.5em 2.5em !important;
  border-radius: 1.4em !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 1.8rem;
  color: #01acf1 !important;
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.pending-btn,
.pending-btn:hover {
  padding: 0.5em 2.5em !important;
  border-radius: 1.4em !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 1.8rem;
  color: grey !important;
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
}
