.welcome-modal {
  max-width: 110rem;
  align-self: safe center !important;
  .modal-body {
    padding: 0;
  }
  .name-highlight {
    color: #44b58e;
  }
  .congrats-blue {
    color: #135070;
  }
  .welcome-btn-alt,
  .welcome-btn {
    padding: 0.1rem !important;
    border-radius: 1.4rem;
    font-weight: bold;
    text-transform: capitalize;
    background: #f0a934;
    border: 1px solid #f0a934;
    color: white;
    width: 18.8rem;
    height: 4rem;
    background: #f0a934 0% 0% no-repeat padding-box;
    border-radius: 4.5rem;
    opacity: 1;
    font: normal normal bold 2rem Arial;
  }
  .welcome-btn-alt {
    width: 30rem;
  }
  .welcome-btn-alt:focus,
  .welcome-btn-alt:hover,
  .welcome-btn:focus,
  .welcome-btn:hover {
    background: #f0a934 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    border: 1px solid #f0a934;
  }
}
