@import "../../variables.scss";

.sx-accent-button {
  border-radius: 3rem;
  background-color: #f2f7fd;
  color: $accent-1;
  border-color: #b4cecd;
  padding: 0.5rem 1rem;
  font-size: $font-size-small;

  &:hover,
  &:focus,
  &:active {
    background-color: $accent-1 !important;
    border-color: $accent-1;
    color: $white;
  }

  &--connected-vehicle {
    background-color: lighten(#90a5b3, 10);
    border-color: #90a5b3;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#90a5b3, 10) !important;
      border-color: #90a5b3;
      color: $white;
    }
  }

  &--canada {
    background-color: lighten(#9092b3, 10);
    border-color: #9092b3;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#9092b3, 10) !important;
      border-color: #9092b3;
      color: $white;
    }
  }

  &--pandora {
    background-color: lighten(#7196c7, 10);
    border-color: #7196c7;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#7196c7, 10) !important;
      border-color: #7196c7;
      color: $white;
    }
  }

  &--grid {
    background-color: #307999;
    border-color: #307999;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#307999, 10) !important;
      border-color: darken(#307999, 10) !important;
      color: $white;
    }
  }

  &--danger {
    background-color: lighten(#dc3545, 10);
    border-color: #dc3545;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#dc3545, 10) !important;
      border-color: #dc3545;
      color: $white;
    }
  }

  &--success {
    background-color: lighten(#21751b, 10);
    border-color: #21751b;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#21751b, 10) !important;
      border-color: #21751b;
      color: $white;
    }
  }
  &--warning {
    background-color: darken($accent-2, 10);
    border-color: $accent-2;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($accent-2, 10) !important;
      border-color: $accent-2;
      color: $white;
    }
  }
}
