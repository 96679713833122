@import "../../variables.scss";

.course-library {
  border-radius: 1.4rem;
  overflow: hidden !important;
  .course-library-div {
    padding: 0;
  }
  .categories-div {
    height: 20.3rem;
    padding-top: 0.5em;
    background: rgb(190, 203, 216);
    opacity: 1;
    .category-div {
      width: 29.1rem;
      height: 14.7rem;
      text-align: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 15px #bbc5c9;
      border-radius: 1rem;
      opacity: 1;
      cursor: pointer;
      .category-icon {
        margin-top: 10%;
        width: 5rem;
      }
      .category-icon-img {
        margin-top: 4rem;
        width: 5rem;
        height: 5rem;
        opacity: 0.8;
      }
      .category-icon-img-info {
        margin-top: 10%;
        width: 4rem;
        height: 5rem;
        opacity: 0.8;
      }
      .category-icon-font {
        color: #c3cfdd;
      }
      .category-text-font {
        color: #707070;
      }
      .font-white {
        color: rgba(255, 255, 255, 0.5);
      }
      .font-selected {
        color: #12506f;
      }
    }

    .selected {
      width: 27.8rem;
      height: 16.3rem;
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-bottom: 0.6rem solid #4e42c1;
      border-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      box-shadow: 0px 0px 15px #bbc5c9;
      // border-bottom-left-radius: 0;
      // border-bottom-right-radius: 0;
      margin-top: -0.8rem;
      opacity: 1;
    }

    .selected:after {
      content: " ";
      width: 0px;
      height: 0px;
      border-top: 0.85rem solid #4e42c1;
      border-left: 0.85rem solid transparent;
      border-bottom: 0.85rem solid transparent;
      border-right: 0.85rem solid transparent;
      position: absolute;
      left: 50%;
      top: calc(100% + 6px);
      margin-left: -0.85rem;
    }
  }

  .course-div {
    .course-title {
      font: normal normal normal 1.6rem Arial;
    }
    .course-skillpoints {
      font: normal normal normal 1.2rem Arial;
    }
    .course-description {
      font: normal normal normal 1.2rem Arial;
      .view-details-text {
        cursor: pointer;
        color: #01acf1;
        font-size: 1.6rem;
        text-decoration: underline;
      }
    }

    .rating-text {
      font-size: 16px;
      font-weight: bold;
      color: #ff9933;
      margin-right: 20px;
    }
    .course-thumbnail {
      border-radius: 1.5rem;
      width: 34rem;
      height: 20rem;
      border: 0.01rem solid rgba(112, 112, 112, 0.3);
      position: relative;
      cursor: pointer;
      // padding-bottom: -0.8rem
      .play-icon-container {
        background-color: #02acf1;
        height: 2.3rem;
        width: 2.3rem;
        border-radius: 1.15rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1.5rem;
      }
      .complete-img {
        height: calc(100% - 8px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .progress-bar {
        background-color: #41c4fd !important;
        height: 0.8rem;
        position: absolute;
        top: calc(100% - 8px);
        margin-left: 1px;
        border-bottom-left-radius: 1.5rem;
      }
      .complete-bar {
        background-color: #41c4fd !important;
        height: 0.8rem;
        top: calc(100% - 8px);
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem !important;
      }
      .progress {
        background-color: transparent;
      }
    }
  }
}

.course-details-card {
  width: 52rem !important;
  margin-right: -45rem !important;
  border: 1px solid #9e9e9e;
  -webkit-box-shadow: 3px 5px 5px #9e9e9e;
  -moz-box-shadow: 3px 5px 5px #9e9e9e;
  box-shadow: 3px 5px 5px #9e9e9e;

  .course-details-img {
    width: 52rem;
    height: 30.5rem;
    object-fit: cover;
  }

  .play-icon-container {
    background-color: #02acf1;
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 1.15rem;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .close-container {
    height: 4rem;
    width: 4rem;
    padding: 1rem;
    font: normal normal bold 2rem Arial;
    color: white;
    position: absolute;
    top: 0;
    left: calc(100% - 4rem);
    cursor: pointer;
  }
  .duration-container {
    background-color: #3b3b3b;
    height: 4rem;
    width: 11.5rem;
    font: normal normal bold 2rem Arial;
    color: white;
    position: absolute;
    top: calc(30.5rem - 2rem);
    left: calc(100% - 5.75rem);
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  .learning-path-text {
    color: #01acf1;
    font-size: 1.6rem;
  }
  .details-row {
    margin: 0;
    padding: 0;
    :not(:last-child) {
      border-right: 1px solid grey !important;
      padding-right: 0.7rem;
    }
    :not(:first-child) {
      padding-left: 0.7rem;
    }
  }
  .description-container {
    height: 140px !important;
    overflow-y: scroll;
    padding-right: 10px;
    justify-content: safe;
  }
  ::-webkit-scrollbar {
    width: 3px;
    scroll-behavior: smooth;
  }
  ::-webkit-scrollbar-track {
    background: rgb(245, 245, 245);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #01acf1;
    border-radius: 1.5px;
  }
}

.student-stats {
  border-radius: 1.4rem;

  .nav {
    justify-content: space-between;
  }
  .nav-tabs {
    padding: 0.5rem;
  }

  .badge-icon {
    width: 7.1rem;
    img {
      width: 100%;
    }
  }

  .achievement-text {
    color: #6633cc;
    font-size: 1.9rem;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .achievement-title {
    font-size: 1.6rem;
    letter-spacing: 1px;
  }

  .stats-value {
    font: normal normal bold 2.9rem Arial;
    letter-spacing: 0px;
    color: #707070;
  }
  .stats-text {
    font: normal normal normal 1.4rem Arial;
    letter-spacing: 0px;
  }

  .stats-div:not(:last-child) {
    border-right: 0.1rem solid rgba(112, 112, 112, 0.3);
  }
  
  .stats-div {
    .stat-div.stat-img {
      vertical-align: top;
    }
    .stat-val-text {
      width: 50%;
    }
    .stat-div {
      display: inline-block;
      h5, p {
        text-align: left;
      }
    }
  }
}

.student-progress {
  border-radius: 1.4rem;
  padding: 0;

  .card-header {
    border: none;
  }
  .card-body {
    padding: 0;
    .progress-text {
      font-size: 2rem;
    }
  }
  .nav-tabs {
    border: 0.05rem solid rgba(112, 112, 112, 0.2);
    padding: 0;
    width: 100%;
    .nav-link {
      width: 50%;
      border-radius: 0;
      font-size: $base-font-size;
      color: $active-tab-text-color;
      padding: 1.3rem 3rem;
      text-align: center;
      &.active {
        border-bottom: 0.4rem solid #6633cc;
        color: #6633cc;
      }
      margin: 0;
    }
  }
  .nav-link:not(:last-child) {
    border-right: 0.05rem solid rgba(112, 112, 112, 0.2);
  }

  .course-container {
    padding: 2.5rem 1rem 1rem 1rem;
  }
  .course-container:not(:last-child) {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  }
  .course-video-container {
    width: 100%;
    border: 0.1rem solid rgba(112, 112, 112, 0.3);
    position: relative;
    cursor: pointer;
    .course-thumbnail {
      width: 100%;
      height: 8.4rem;
      object-fit: cover;
    }
    .play-icon-container {
      background-color: #02acf1;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 0.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
    }
    .progress-bar {
      background-color: #41c4fd !important;
      height: 0.6rem;
      position: absolute;
      bottom: 0px;
    }
    .progress {
      height: 0.6rem;
    }
  }
  .course-title {
    text-align: left;
    font: normal normal bold 1.4rem Arial;
    color: #040409;
  }
  .skill-points {
    font: normal normal normal 1.4rem Arial;
  }
  .skill-points-text {
    font: normal normal bold 1.2rem Arial;
  }
}

@media (max-width: 1925px) {
  .student-stats {
    .stat-div.stat-img {
      position: absolute;
      right: 0%;
    }
  }
}
@media (min-width: 1925px) {
  .course-library {
    .course-div {
      .course-thumbnail {
        // width: 100%;
        height: 34rem;
        // img {
        //   object-fit: cover;
        // }
      }
    }
  }
  .student-progress {
    .course-video-container {
      .course-thumbnail {
        height: 13rem;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 2500px) {
  .course-library {
    .course-div {
      .course-thumbnail {
        height: 30rem;
        width: 100%;
      }
    }
  }
  .student-progress {
    .course-video-container {
      .course-thumbnail {
        height: 16rem;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 3000px) {
  .course-library {
    .course-div {
      .course-thumbnail {
        height: 40rem;
      }
    }
  }
  .student-progress {
    .course-video-container {
      .course-thumbnail {
        height: 20rem;
        object-fit: cover;
      }
    }
  }
}

@media (min-width: 3500px) {
  .course-library {
    .course-div {
      .course-thumbnail {
        height: 50rem;
      }
    }
  }
  .student-progress {
    .course-video-container {
      .course-thumbnail {
        height: 24rem;
        object-fit: cover;
      }
    }
  }
}