@import "../../variables.scss";

.sx-domain-card {
  position: relative;
  background-color: $accent-1;
  color: $white;
  min-height: 20rem;
  cursor: pointer;

  &__info {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__title {
    font-size: $base-font-size;
    font-weight: bold;
  }
}
