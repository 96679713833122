@import "../../variables.scss";

.Categories {
  &__section-categories {
    background-color: lighten($body-background-color, 3);
    border: 1px solid $gray-300;
  }

  &__assets-section {
    background-color: $white;
    border: 1px solid $gray-300;
  }
}

.categories-cnt {
  border-bottom: 1px solid #e5dada;
}

.doc-links-cnt {
  font-size: 1.3rem;
  border-top: 1px solid #e5dada;
}
