@import "../../variables.scss";

.sx-video-playlist {
  &__playlist-wrapper {
    background-color: #e6eaef;
    padding: 2rem 2rem 1rem 2rem;

    max-width: 100%;
    overflow: auto;
    overflow-y: hidden;
  }

  .playlist-item {
    max-width: min-content;
    .image-container {
      cursor: pointer;
      border: 1px solid $gray-100;
      transition: all 0.2s ease;

      img {
        transition: all 0.2s ease;
      }

      .sx-play,
      .sx-pause {
        transition: all 0.2s ease;
        font-size: 2.2rem;
      }

      &:hover {
        border: 4px solid $accent-1;

        .sx-play,
        .sx-pause {
          color: $accent-1 !important;
          font-size: 2.6rem;
        }

        img {
          height: calc(11.4rem - 4px);
          width: calc(20.3rem - 4px);
        }
      }

      &.active {
        border: 4px solid $accent-1;

        img {
          height: calc(11.4rem - 4px);
          width: calc(20.3rem - 4px);
        }
      }
      position: relative;

      &__play-button {
        font-size: 2rem;
        color: $white;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translateX(-50%);

        .sx-pause {
          color: $accent-1;
        }
      }

      &__duration {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        color: $white;
        font-size: $font-size-xsmall;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right, #000000, #000000);
        opacity: 0.5;
      }
    }
    img {
      height: 11.4rem;
      width: 20.3rem;
    }
  }
}
