@import "../../variables.scss";

$asset-card-border-radius: 1.5rem;
$badge-size: 2.2rem;
$popout-height: 10rem;
$brand-height: 2.6rem;

.sx-asset-card {
  border-radius: 0;
  border-bottom-right-radius: $asset-card-border-radius;
  border-bottom-left-radius: $asset-card-border-radius;
  position: relative;

  &__hover-wrapper:hover {
    .sx-asset-card__brand-section {
      height: $popout-height;
      top: -$popout-height;

      .sx-asset-card__brand-text {
        display: none;
        opacity: 0;
      }

      .sx-asset-card__brand-button {
        display: block;
        opacity: 1;
      }

      .sx-asset-card__hidden-text {
        opacity: 1;
        height: 100%;
      }
    }
  }

  &__hidden-text {
    font-size: $font-size-xsmall;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    height: 0;
    overflow: auto;
    max-height: calc(#{$popout-height} - #{$brand-height} - 1.5rem);
    transition: opacity 0.2s ease;
    transition-delay: 0.1s;
  }
  &__brand-text {
    font-size: $font-size-base;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  &__brand-button {
    padding-top: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &__brand-section {
    padding: 0.3rem 1rem;
    color: $white;
    height: $brand-height;
    border-top-right-radius: $asset-card-border-radius;
    border-top-left-radius: $asset-card-border-radius;
    text-align: center;
    font-size: $font-size-small;
    align-self: flex-end;
    transition: all 0.3s ease;
    position: absolute;
    top: -$brand-height;
    width: 100%;
  }
  &__header-section {
    background-color: #f2f7fd;
    border-bottom: 1px solid #b4cecd;
  }

  &__table-section {
    table {
      width: 100%;
    }
  }

  &__inherit-border {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &__badge-wrapper {
    top: 3px;
  }

  &__badge {
    font-weight: bold;
    font-size: $font-size-xsmall;
    color: $white;
    background-color: $accent-2;
    text-align: center;
    line-height: $badge-size;
    height: $badge-size;
    width: $badge-size;
    position: absolute;
    border-radius: 50%;
    top: -1rem;
    left: 1rem;
  }
}
.dgc-values-popover {
  max-width: 50%;
  min-width: 300px;
}
