@import "../../variables.scss";

$expandable-menu-padding-x: 1.6rem;
$active-background-color: #eef2f5;
.card-header {
  background-color: #fff;
}
.sx-question-answer {
  background-color: $white;

  &__item-title {
    display: flex;
    align-items: flex-start;
    line-height: 4.8rem;
    font-weight: bold;
    color: $primary;
  }
  &__item-abbr {
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 0.8rem;
    font-size: 2.8rem;
    color: $accent-2;
  }

  &__item {
    font-size: $base-font-size;
    padding-left: $expandable-menu-padding-x;
    padding-right: $expandable-menu-padding-x;
    border-top: 1px solid $gray-200;
    transition: background-color 0.1s ease;
    cursor: pointer;

    &.active-with-body {
      background-color: $active-background-color;
    }

    &.active-link {
      background-color: $active-background-color;
      border-left: 3px solid $accent-blue;
    }

    &:hover {
      background-color: $active-background-color;
    }
  }

  &__item-body {
    padding-left: $expandable-menu-padding-x;
    padding-right: $expandable-menu-padding-x;
  }

  &__toggle-button {
    color: $accent-blue;
    font-weight: bold;
    font-size: $font-size-medium;
  }
}
