@import "../../variables.scss";

.sx-chip {
  color: $secondary;
  border: 1px solid $secondary;
  font-size: $font-size-small;
  padding: 1rem 2rem 0.8rem 2rem;
  border-radius: 3rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  &__close {
    font-size: $font-size-medium;
    padding-left: 1rem;
    color: lighten($danger, 15);
    cursor: pointer;
    margin-bottom: 5px;
  }
}
