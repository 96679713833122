@import "../../variables.scss";

.sx-category-card {
  position: relative;
  width: 20rem;
  height: 12rem;
  background-color: $accent-1;
  color: $white;

  &__auth {
    position: absolute;
    color: lighten($accent-1, 25);
    left: 1rem;
    top: 1rem;
  }

  &__info {
    position: absolute;
    color: lighten($accent-1, 25);
    right: 1rem;
    top: 1rem;
  }

  &__text {
    font-size: 1.8rem;
  }

  &.active {
    background-color: darken($accent-1, 15);
    transform: scale(1.1);

    .sx-category-card {
      &__info,
      &__auth {
        color: $white;
      }
    }
  }
}
