@import "../../variables.scss";

.nav-tabs {
  padding-left: 3.2rem;
  padding-top: 1.6rem;

  .nav-link {
    border: none;
    font-size: $base-font-size;
    color: $active-tab-text-color;
    margin-right: 1rem;

    &.active {
      border-bottom: 0.5rem solid $accent-blue;
      color: $primary;
    }
  }
}
