// Needs to stay outside of .Events because modals are rendered outside the component tree, and apended to the body
.admin-modal {
  .modal-dialog {
    max-width: 900px;
  }
}

.GroupManagement {
  .nav-tabs .nav-link.active {
    background-color: #12506f;
    border-bottom: 0.5rem solid #1c7fa2;
    color: white;
  }
}
