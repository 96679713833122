@import "../../variables.scss";

.sx-info-card {
  height: 100%;
  // box-shadow: 0px 0px 65px #d3d3d3;
  width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sx-green {
    background: transparent linear-gradient(180deg, #00b0c3 0%, #005f69 100%) 0%
      0% no-repeat padding-box;
    color: $white;
  }
  .sx-blue {
    background: transparent linear-gradient(180deg, #00a2d3 0%, #005baa 100%) 0%
      0% no-repeat padding-box;
    color: $white;
  }
  .sx-purple {
    background: transparent linear-gradient(180deg, #c1b9fd 0%, #7569e0 100%) 0%
      0% no-repeat padding-box;
    color: $white;
  }
  &__image {
    padding: 5%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  &__title {
    text-align: center;
    font-size: 2.8rem;
    margin-bottom: 0.8rem;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 0;
    padding: 1rem;
  }
  &__linkText {
    color: #01acf1;
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;
  }
  &__subtitle {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1rem;
    color: #212529;
    text-align: left;
  }
  &__box {
    margin-bottom: 0.8rem;
    margin-top: 0;
    padding: 1rem;
  }
  &__button {
    border: none;
    cursor: pointer;
    color: #01acf1;
    float: right;
    :hover {
      color: #9ad73c;
    }
  }
}

.course-popover {
  max-width: 50vw;
  min-width: 500px;
  padding: 30px;
  border: none;
  box-shadow: -12px 3px 74px #00000043;
  border-radius: 13px;
  img {
    min-width: 40px;
  }

  .course-popover-overflow {
    max-height: 50vh;
  }

  .icon-container {
    // border: 1px solid #626161;
    border-radius: 50%;
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
