@import "../../variables.scss";

.ServiceNowInstructions {
  .bg-body-color {
    background-color: $body-background-color;
  }

  .modal-dialog {
    max-width: 800px;
  }
  .modal-header {
    background-color: $accent-1;
    color: white;
    .close {
      transform: scale(1.5);
      color: white;
      font-weight: normal;
      &:focus {
        outline: none;
      }
    }
  }
}
