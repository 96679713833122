@import "../../variables.scss";

.sx-nav-link-list {
  background-color: $primary;
  min-height: 100vh;

  &__header-section {
    color: #ffffff;
    opacity: 0.4;
    font-size: $font-size-small;
    height: $navbar-height;
    border-bottom: 1px solid $navbar-active-color;
  }

  &__list-container {
    overflow: auto;
    max-height: calc(100vh - #{$navbar-height});
  }

  &__list {
    padding-left: 0;
  }
}
