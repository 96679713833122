@import "../../variables.scss";

.sx-nav-link-item {
  background-color: $primary;
  height: $navbar-height;
  list-style-type: none;
  color: $white;
  font-size: $font-size-xsmall;
  transition: background-color 0.2s ease;

  img {
    height: 2rem;
    width: 2.5em;
  }

  &:hover {
    &:not(.disabled) {
      background-color: $navbar-active-color;
    }
  }

  &.active {
    background-color: $navbar-active-color;
  }

  &.disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: unset;
  }
}
