@import "../../variables.scss";

$expandable-menu-padding-x: 1.6rem;
$green-1: #448a98;
$green-2: darken($green-1, 10%);
$green-3: darken($green-1, 5%);
$green-0: lighten($green-1, 15%);

.video-courses {
  .row {
    margin: 0;
  }

  .video-container {
    width: 100%;
    padding: 0 100px;
  }

  .loading-indicator {
    text-align: center;
    width: 100%;
    position: relative;
    top: 50%;
    color: $white;
  }

  .left-menu {
    // height: 100vh;
    position: absolute;
    width: 100%;

    .col-1-bg-courses {
      background-color: $white !important;
    }

    .col-2-bg-courses {
      background-color: $white !important;
    }
  }

  .courses-content-title,
  .courses-content-icon {
    padding: $expandable-menu-padding-x $expandable-menu-padding-x !important;
    // color: white;
    font: normal normal normal 24px Arial;
  }

  .sx-expandable-menu {
    background-color: $green-1 !important;
    border: 0;
    border-radius: 0;

    &__title {
      font-size: $font-size-medium;
      // color: $white;
      padding: 0 !important;
    }

    .courses-item-title,
    .courses-item-icon {
      padding: $expandable-menu-padding-x * 1.1 $expandable-menu-padding-x !important;
      border-top: 1px solid #cdd0d1;
      border-bottom: 1px solid #cdd0d1;
    }

    .courses-body-title,
    .courses-body-icon {
      padding: $expandable-menu-padding-x $expandable-menu-padding-x !important;
    }

    .courses-icon {
      // background-color: $green-0;
      text-align: center;
    }

    .courses-item-icon {
      background-color: #f6f9fa;
    }

    .courses-item-title {
      background-color: #f6f9fa;
    }

    &__item-title {
      font-weight: bold;
      font-size: 1.3rem;
      font: normal normal bold 18px Arial;
      color: #6e6e6e;
      width: 100%;
    }

    &__item {
      font-size: $base-font-size;
      font: normal normal bold 16px Arial;
      padding: 0 !important;
      border: none !important;
      // transition: background-color 0.1s ease;
      cursor: pointer;
      // color: $white !important;
    }

    &__item-body {
      padding: 0 !important;
      font: normal normal bold 16px Arial;
      background-color: $white !important;
      // color: $white !important;
    }

    &__toggle-button {
      // color: $green-0;
      font-weight: bold;
      font-size: $font-size-medium;
      position: absolute;
      right: 10px;
    }
  }
}

.instructor {
  color: #8e8e8e;
  font-size: 18px;
}
.instructor-name {
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  color: 000000;
}
.course-title {
  color: 000000;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: -3px;
}
.skill-class,
.skill-desc {
  font-size: 18px;
  color: #777777;
  margin-bottom: 0;
}

.skill-desc {
  font-size: 17px;
}

.license-sxm {
  text-align: right;
  color: rgb(179, 173, 173);
  font-size: 10px;
}
.license-sxm {
  a:link,
  a:visited,
  a:active {
    display: inline-block;
    color: rgb(179, 173, 173);
    font-size: 10px;
    cursor: pointer;
  }

  a:hover {
    font-size: 10px;
    display: inline-block;
    color: grey !important;
  }
}
