@import "../../variables.scss";

.Data-University {
  .section-title {
    color: #736e7d;
  }
  .section-features {
    background: white;
    .learningpath-modal {
      width: 1335px;
      height: 931px;
    }
  }

  .section-footer {
    background: #f5f5f5 0% 0% no-repeat;
    border: 1px solid #c7dae2;
    color: #ffffffbd;
    .footer-blue {
      background: transparent linear-gradient(180deg, #00a2d3 0%, #005baa 100%)
        0% 0% no-repeat;
      color: $white;
    }
    .badge {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 2rem;
      background: #08273770 0% 0% no-repeat;
      color: $white;
      padding: 1.6rem 3.2rem;
      border-top-right-radius: $base-border-radius;
      border-bottom-left-radius: calc(#{$base-border-radius} * 5);
    }
  }
}

.category-modal {
  // max-width: 1200px;
  .start-btn {
    padding: 1rem 5rem !important;
    border-radius: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #3cdb81;
    border: 1px solid #3cdb81;
    color: white;
    height: 6rem;
    background: #3cdb81 0% 0% no-repeat padding-box;
    border-radius: 4.5rem;
    opacity: 1;
    font: normal normal bold 2.2rem Arial;
  }

  .pending-btn {
    padding: 1rem 5rem !important;
    border-radius: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #3cdb81 !important;
    border: 1px solid #3cdb81 !important;
    color: white;
    height: 6rem;
    background: #3cdb81 0% 0% no-repeat padding-box !important;
    border-radius: 4.5rem;
    opacity: 1;
    font: normal normal bold 2.2rem Arial;
  }

  .disabled-btn {
    padding: 1rem 5rem !important;
    border-radius: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: white !important;
    border: 1px solid grey !important;
    color: grey;
    height: 6rem;
    background: white 0% 0% no-repeat padding-box !important;
    border-radius: 4.5rem;
    opacity: 1;
    font: normal normal bold 2.2rem Arial;
  }

  .start-btn:focus,
  .start-btn:hover {
    background: rgba(60, 219, 129, 0.6) 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    border: 1px solid #3cdb81;
  }
}
