@import "../../variables.scss";

$expandable-menu-padding-x: 1.6rem;
$active-background-color: #eef2f5;

.sx-expandable-menu {
  background-color: $white;
  &__title {
    font-size: $font-size-medium;
    color: $gray-600;
    padding-left: $expandable-menu-padding-x;
    padding-right: $expandable-menu-padding-x;
  }

  &__items-container {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__item-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
  }

  &__item {
    font-size: $base-font-size;
    padding-left: $expandable-menu-padding-x;
    padding-right: $expandable-menu-padding-x;
    border-top: 1px solid $gray-200;
    transition: background-color 0.1s ease;
    cursor: pointer;

    &.active-with-body {
      background-color: $active-background-color;
    }

    &.active-link {
      background-color: $active-background-color;
      border-left: 3px solid $accent-blue;
    }

    &:hover {
      background-color: $active-background-color;
    }
  }

  &__item-body {
    padding-left: $expandable-menu-padding-x;
    padding-right: $expandable-menu-padding-x;

    &.active-with-body {
      background-color: $active-background-color;
    }
  }

  &__toggle-button {
    color: $accent-blue;
    font-weight: bold;
    font-size: $font-size-medium;
  }
}
