@import "../../variables.scss";

.sx-select {
  label {
    white-space: nowrap;
    margin-bottom: 0;
  }

  select {
    border-radius: 3.2rem;
    font-size: $font-size-small;
    padding-top: 0;
    padding-bottom: 0;
    height: 3.2rem;
  }

  .select-welcome {
    border-radius: 0;
    font-size: $font-size-small !important;
    padding-top: 0;
    padding-bottom: 0;
    height: 4rem !important;
    width: 22.7rem !important;
  }
}
