@import "../../variables.scss";

.sx-select-search-form {
  position: relative;

  .input-group {
    height: 2.5em;
  }

  input {
    height: 100%;
    padding: 1em;
    border-radius: 3.2rem;
    background-color: $white;
    font-size: $font-size-small;
    min-width: 8em !important;
    transition: all 0.2s ease;

    &::placeholder {
      color: $gray-400;
    }

    &:focus {
      min-width: 20em !important;
    }
  }

  .search-btn,
  .search-btn:focus,
  .search-btn:active {
    right: 2rem;
    height: 100%;
    width: 8rem;
    background: linear-gradient($accent-2, darken($accent-2, 20));
    border-color: $accent-2 !important;
    border-radius: 3.2rem !important;

    &:hover {
      background-color: darken($accent-2, 20);
    }
  }

  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:active,
  .dropdown-toggle:focus,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    border-top-left-radius: 3.2rem;
    border-bottom-left-radius: 3.2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 1.4rem;
    height: 100%;
    background-color: lighten($accent-1, 40);
    color: $primary;
    /* max-width: 8em;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .dropdown-item {
    font-size: 1.4rem;
    padding: 0.7rem;
  }
}
