@import "../../variables.scss";

.info-circle {
  vertical-align: text-top;
  margin-right: 1rem;
}

.dgc-legend-modal {
  .modal-dialog {
    min-width: 90%;
    // font-size: smaller;
  }
  .modal-header {
    background-color: #f2f7fd;
  }
  .first-col {
    width: 12% !important;
    font-weight: bold;
  }
  th {
    border-top: none;
    vertical-align: middle !important;
  }
  th,
  td {
    width: 18%;
  }
}
