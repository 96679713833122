@import "../../variables.scss";

.sx-grid {
  border-top-right-radius: $base-border-radius;
  border-top-left-radius: $base-border-radius;
  margin-bottom: 5.1rem;
  &__title-bar {
    background-color: $table-head-color;
    height: 5rem;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
  }

  &__title {
    color: $white;
    font-size: $font-size-small;
    font-weight: bold;
  }

  &__search-icon {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
  }

  &__quick-filter-form {
    padding-right: 1rem;
    .form-group {
      margin-bottom: 0;
    }
  }

  &__quick-filter-input {
    border-radius: 2rem;
    padding-left: 2rem;
  }

  // AG Grid Overrides
  .ag-header-viewport {
    background-color: darken($table-head-color, 10);
  }

  .ag-row-odd {
    background-color: darken($gray-100, 2);
  }

  .ag-row:hover {
    background-color: darken($gray-100, 4);
  }

  .ag-root-wrapper-body {
    border-right: 1px solid $gray-200;
  }
  .ag-body-viewport {
    border: 1px solid $gray-200;
  }

  .ag-header-row.ag-header-row-column {
    background-color: darken($table-head-color, 10);
    color: $white;

    .ag-header-icon,
    .ag-header-label-icon {
      color: $white;
    }
  }

  .ag-header-cell:hover,
  .ag-header-cell-sortable:hover {
    background-color: darken($table-head-color, 15) !important;
  }
}

.sx-grid2 {
  // AG Grid Overrides
  .ag-header-container {
    width: 100% !important;
  }

  .ag-header-row.ag-header-row-column {
    background-color: $table-gray-bg-1;
    color: darken($secondary, 5);
    font-size: 1.4rem;
    width: inherit !important;

    .ag-header-icon,
    .ag-header-label-icon {
      color: darken($secondary, 5);
    }
  }

  .ag-header-cell:hover,
  .ag-header-cell-sortable:hover {
    background-color: $table-gray-bg-1 !important;
  }
}
