@import "../../variables.scss";

.du-banner-height {
  background-image: url("/assets/images/data-university-landing/Data_University_Banner.png");
  background-size: cover;
  background-position: center;
  .intro-text {
    font-size: 2rem;
  }
  .kit-text {
    font-size: 1.6rem;
    line-height: 2, 2rem;
  }
  .du-logo-wrap {
    position: relative;
    span {
      position: relative;
      top: .1rem;
      padding-left: .3rem;
    }
  }
}

.border-right-not-last:not(:last-child) {
  border-right: 2px solid #52adb9;
}

.du-colorText {
  color: #f6b447;
}

.text1 {
  font-size: 40px;
  margin-left: 3.8rem;
  @media (max-width: 1024px) {
    font-size: 32px;
  }
}
.text2 {
  font-size: 80px;
  line-height: 80px;
  @media (max-width: 1024px) {
    font-size: 60px;
    line-height: 60px;
  }
}
.text3 {
  margin: 1.5rem 0;
  font: normal normal normal 3.2rem Arial;
  @media (max-width: 1024px) {
    font: normal normal normal 3rem Arial;
  }
}
.text4 {
  font-size: 18px;
  font-weight: bold;
}

.info-1 {
  padding-left: 0vw;
}
.info-2 {
  padding-left: 2vw;
}
.info-3 {
  padding-left: 4vw;
}
.numberBox {
  font-weight: bold;
  background: #146469;
  border-radius: 10%;
  color: #fff;
  margin: 0 auto;
  font-size: 70px;
  text-align: center;
  max-width: 80px;
  padding: 10px;
}

.attentionText {
  letter-spacing: -1.65px;
  font-weight: bold;
}

.pending-btn,
.pending-btn:hover {
  padding: 0.5em 2.5em !important;
  border-radius: 1.4em !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 1.8rem;
  color: #c9c9c9;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff !important;
  cursor: default !important;
}
