@import "../../variables.scss";

.JupyterNotebooksPages {
  .right-section {
    background-color: $light-gray-2;
    border-bottom-right-radius: $base-border-radius;
    border-left: 1px solid $gray-line-color;
    padding-left: 0;
    padding-right: 0;
  }

  .input-field {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid $gray-line-color;
    outline: none;
    width: 100%;
  }

  .sign-in-btn {
    width: 50%;
    background-color: #f9b64b !important;
    border-color: #f9b64b !important;
    color: white !important;
  }
  
  .sign-in-btn-disabled {
    width: 50%;
    background-color:   rgb(167, 169, 171) !important;
    border-color:   rgb(167, 169, 171) !important;
    color: white !important;
  }

  .key-benefits,
  .jupyterhub-resources {
    padding: 5rem;
    border-bottom: 1px solid $gray-line-color;
  }

  .resource-tiles {
    padding: 5rem;
  }

  a.download-button {
    background-color: $white;
    border-radius: 3rem;
    border-color: $white;
    opacity: 0.8;
    color: $accent-1;
    font-size: $font-size-small;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .SelfServiceCard {
    background-color: $accent-1;
    color: $white;

    a.download-button {
      background-color: $white;
      border-radius: 3rem;
      border-color: $white;
      opacity: 0.8;
      color: $accent-1;
      font-size: $font-size-small;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
