@import "../../variables.scss";

.sx-navbar-body {
  border-right: 1px solid $navbar-active-color;
  border-left: 1px solid $navbar-active-color;
}

.banner-icon {
  color: $accent-1;
}

.banner-icon-disabled {
  color: $gray-line-color;
}

.banner-popover {
  max-width: 400px;
  min-width: 300px;
  img {
    min-width: 80px;
  }
}

.banner-popover-overflow {
  max-height: 50vh;
  overflow-y: scroll;
}

.overflow-word-break {
  overflow: auto;
  word-break: break-word;
}

.request-button {
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
}

.bg-lighter-blue {
  background-color: #eef2f5;
}

.ReportViewer {
  .breadcrumb {
    padding: 0.5em !important;
  }
}
.MetricViewerHeader {
  background: darken($table-head-color, 10);
  color: $white;
  font-size: $font-size-medium;
}

.MetricViewer {
  .metric-info {
    display: inline-block;
  }
}

.rag-blue-highlight {
  background-color: #cceffc !important;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  1.3% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  3.7% {
    transform: translateX(5px) rotateY(7deg);
  }

  6.3% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  8.7% {
    transform: translateX(2px) rotateY(3deg);
  }

  10% {
    transform: translateX(0);
  }
}

.headShake {
  animation: headShake 5s infinite ease-in-out;
}

.help-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: 10px 0;
  min-width: 160px;
  min-height: 70px;
  box-shadow: 3px 3px 6px #15223214;
  border-radius: 6px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.help-text {
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0;
  color: #505050;
}

.help-heading {
  color: #040409;
  font-size: 20px;
  border-bottom: 2px solid #e5dada;
  padding: 5px 0;
}
