@import "../../variables.scss";

.sx-tree-view {
  &__expanded-icon {
    color: $table-head-color;
  }
  &__collapsed-icon {
    color: $table-head-color;
  }
  &__folder-icon {
    color: $table-head-color;
  }

  .btn {
    color: $accent-1;
    text-decoration: none;
    font-size: $font-size-small;
    padding-left: 0;
  }
}
