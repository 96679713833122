@import "../../variables.scss";

.DataScienceNotebooks {
  .right-section {
    background-color: $light-gray-2;
    border-bottom-right-radius: $base-border-radius;

    border-left: 1px solid $gray-line-color;
    padding-left: 0;
    padding-right: 0;
  }

  .resource-links {
    padding: 0 3rem 3rem;
    border-bottom: 1px solid $gray-line-color;
  }

  .resource-tiles {
    padding: 5rem;
  }

  .TableauCard {
    background-color: $accent-1;
    color: $white;

    a.download-button {
      background-color: $white;
      border-radius: 3rem;
      border-color: $white;
      opacity: 0.8;
      color: $accent-1;
      font-size: $font-size-small;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
