.sx-video-carousel {
  max-width: 65vw;
  margin: 0 auto;
  video {
    position: relative;
    top: 20px;
  }
  iframe {
    height: 60vh !important;
  }
}
