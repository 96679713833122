@import "../../variables.scss";

.sx-rpt-prmpts {
  .modal-header {
    padding: 10px 10px 5px 15px;
    line-height: 30px;
    background: #3c98c0;
    border-color: #3c98c0;
    color: white;
  }
  .close {
    float: right;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 white;
    opacity: 0.5;
  }
  .form-horizontal {
    .form-group {
      margin-left: 0px;
      margin-right: 0px;
    }
    .control-label {
      line-height: 15px;
      padding-top: 0px;
      font-size: 12px;
      clear: both;
      float: left;
      margin-right: 15px;
      text-align: right;
      display: block;
    }
  }
  @media (min-width: 768px) {
    .form-horizontal .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
    }
  }
  .form-control {
    height: 32px;
    font-size: 12px;
    line-height: 18px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: appearance;
    border: 1px solid #d5d5d5;
    background: #f9f9f9;
    min-width: 250px;
  }
  select[multiple],
  select[size] {
    min-height: 120px;
    min-width: 300px;
  }
  .control-label-required {
    color: red;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
  }
  a {
    color: #337ab7 !important;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
  }
}
